<template>
  <validation-observer ref="form" v-slot="{ invalid }" slim>
    <layout width="540" :loading="isInit">
      <template slot="header">
        {{ title }}
      </template>

      <template>
        <v-form>
          <validation-provider
            v-slot="{ errors }"
            name="Rule name"
            rules="required"
          >
            <v-text-field
              v-model.trim="state.name"
              label="Rule name"
              :error-messages="errors"
            />
          </validation-provider>
          <v-select
            v-model="state.objectTypeId"
            :items="typesList"
            item-text="name"
            item-value="id"
            label="Type name"
          />
          <form-subheader v-if="!!monitoringItems.length" title="Push items" />
          <template v-for="item in monitoringItems">
            <form-switcher
              :key="item.id"
              :value="!!state.forwardMonitoringItems[item.id]"
              :title="item.infoName.value"
              @input="handleToggleMonitoringItem(item.id, $event)"
            />
            <validation-provider
              v-if="state.forwardMonitoringItems[item.id]"
              :key="`${item.id}_messageType`"
              v-slot="{ errors }"
              name="Message template"
              rules="required"
              :immediate="true"
            >
              <v-select
                :key="`${item.id}-message-type`"
                v-model="state.forwardMonitoringItems[item.id].messageTypeId"
                :items="messageTypes"
                :error-messages="errors"
                item-text="name"
                item-value="id"
                label="Message template"
              />
            </validation-provider>
          </template>
          <form-subheader title="Filters" />
          <select-chips
            v-model="state.objectGroupIds"
            multiple
            :items="objectGroupList"
            label="Groups"
            item-text="name"
            item-value="id"
            chips
            class="ma-0"
            @drop-item="handleDropObjectGroup"
          />
          <select-chips
            v-model="state.geotagIds"
            multiple
            :items="geotags"
            label="Geotags"
            item-text="name"
            item-value="id"
            chips
            class="ma-0"
            @drop-item="handleDropGeotag"
          />
        </v-form>
      </template>
      <template slot="footer">
        <v-spacer />
        <v-btn text color="text-primary" @click.stop="$emit('close')">
          Cancel
        </v-btn>
        <v-btn
          text
          color="primary"
          :disabled="invalid || !hasPushItems"
          depressed
          :loading="loading"
          @click.stop="submit"
        >
          {{ submitBtnTitle }}
        </v-btn>
      </template>
    </layout>
  </validation-observer>
</template>

<script>
import Layout from '@/components/popup/PopupLayoutDefault';
import { ref, onMounted, computed, watch } from '@vue/composition-api';
import { usePromise } from 'vue-composable';
import { required } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import {
  connectionMessageTypeService,
  connectionService,
  forwardingRuleService
} from '@/modules/connections/api';
import { useTypes } from '@/modules/object-types/compositions/types';
import { useObjectGroups } from '@/modules/object-groups/compositions/object-groups';
import { useGeotags } from '@/modules/geotags/compositions/geotags';
import SelectChips from '@/components/_base/SelectChips';
import { monitoringItemService } from '@/modules/object-types/api';
import { objectPropertyService } from '@/modules/common/api';

extend('required', {
  ...required,
  message: 'This field is required'
});

export default {
  name: 'ForwardingRuleEdit',
  components: {
    Layout,
    SelectChips,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    connectionId: {
      type: String,
      default: ''
    },
    forwardingRuleId: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const state = ref({
      name: '',
      schemaId: '',
      objectTypeId: '',
      objectGroupIds: [],
      geotagIds: [],
      forwardMonitoringItems: {}
    });

    const forwardingRule = ref({});

    const monitoringItems = ref([]);

    const messageTypes = ref([]);

    const form = ref(null);
    const isInit = ref(false);

    const { list: typesList } = useTypes();
    const { objectGroupList } = useObjectGroups();
    const { list: geotags } = useGeotags();

    const edit = computed(() => props.forwardingRuleId);
    const title = computed(() =>
      edit.value ? 'Edit forwarding rule' : 'Add forwarding rule'
    );

    const submitBtnTitle = computed(() => (edit.value ? 'Save' : 'Add'));

    const handleDropObjectGroup = id => {
      state.value.objectGroupIds.splice(
        state.value.objectGroupIds.findIndex(i => i.id === id),
        1
      );
    };

    const handleDropGeotag = id => {
      state.value.geotagIds.splice(
        state.value.geotagIds.findIndex(i => i.id === id),
        1
      );
    };

    const handleToggleMonitoringItem = (id, value) => {
      if (!value) {
        state.value.forwardMonitoringItems[id] = value;
      } else {
        state.value.forwardMonitoringItems = {
          ...state.value.forwardMonitoringItems,
          [id]: {
            messageTypeId: ''
          }
        };
      }
    };

    watch(
      () => state.value.objectTypeId,
      async (value, prevValue) => {
        if (value) {
          monitoringItems.value = await monitoringItemService.list({
            parentSchemaId: value
          });
        }
        if (value && prevValue) state.value.forwardMonitoringItems = [];
      }
    );

    const handleSubmit = async () => {
      if (!(await form.value.validate())) return;

      //
      if (!edit.value) {
        // create new forwarding rule
        forwardingRule.value = await forwardingRuleService.create({
          name: state.value.name,
          schemaId: state.value.schemaId
        });

        connectionService.link(props.connectionId, forwardingRule.value.id);
      } else {
        await forwardingRuleService.update(props.forwardingRuleId, {
          name: state.value.name
        });
      }

      await objectPropertyService.updateValues([
        {
          id: forwardingRule.value.filterObjectType.id,
          value: state.value.objectTypeId
        },
        {
          id: forwardingRule.value.filterGroupFilter.id,
          value: state.value.objectGroupIds.map(i => i.id)
        },
        {
          id: forwardingRule.value.filterGeotagFilter.id,
          value: state.value.geotagIds.map(i => i.id)
        },
        {
          id: forwardingRule.value.ruleRuleConfig.id,
          value: Object.entries(state.value.forwardMonitoringItems)
            .filter(([_, value]) => !!value?.messageTypeId)
            .map(([key, value]) => ({
              id: key,
              messageTypeId: value.messageTypeId
            }))
        }
      ]);
      emit('close');
    };

    const { loading, exec: submit } = usePromise(handleSubmit, true);

    onMounted(async () => {
      isInit.value = true;

      messageTypes.value = await connectionMessageTypeService.fetch(
        props.connectionId
      );

      if (!edit.value) {
        state.value.schemaId = await forwardingRuleService.fetchSchemaId();
        if (!state.value.schemaId) {
          console.warn(`Can't fetch connection schema`);
        }
      } else {
        forwardingRule.value = await forwardingRuleService.fetch(
          props.forwardingRuleId
        );

        const {
          filterObjectType,
          filterGroupFilter,
          filterGeotagFilter,
          ruleRuleConfig,
          name
        } = forwardingRule.value;

        console.log(ruleRuleConfig.value);
        state.value = {
          ...state.value,
          name,
          objectTypeId: filterObjectType.value,
          objectGroupIds: objectGroupList.value.filter(i =>
            filterGroupFilter.value.includes(i.id)
          ),
          geotagIds: geotags.value.filter(i =>
            filterGeotagFilter.value.includes(i.id)
          ),
          forwardMonitoringItems: ruleRuleConfig.value.reduce((acc, cur) => {
            acc[cur.id] = cur.messageTypeId
              ? {
                  messageTypeId: cur.messageTypeId
                }
              : false;
            return acc;
          }, {})
        };
      }

      isInit.value = false;
    });

    const hasPushItems = computed(() =>
      Object.values(state.value.forwardMonitoringItems).some(item => !!item)
    );

    return {
      state,
      loading,
      title,
      submitBtnTitle,
      submit,
      edit,
      form,
      typesList,
      monitoringItems,
      objectGroupList,
      handleDropObjectGroup,
      handleDropGeotag,
      geotags,
      handleToggleMonitoringItem,
      messageTypes,
      isInit,
      hasPushItems
    };
  }
};
</script>
